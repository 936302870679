<template>
  <div class="container">
    <div class="title">
      <div class="return" @click="toPre" v-if="!isRegister">返回</div>
      <div>注册达人账号</div>
    </div>
    <a href="https://www.guobo.shop/">
      <div class="top">
        <div class="title1">
          <span style="color: rgba(252, 192, 51, 1)">海量</span>商品等你选购
        </div>
        <div class="title2">国播出国APP</div>
        <div class="title3">跨境直播间达人实时翻译</div>
      </div>
    </a>
    <div v-if="isRegister">
      <div class="form">
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules">
          <el-form-item prop="phone">
            <div class="input">
              <div class="label">邮箱号</div>
              <el-input
                placeholder="输入邮箱号作为登陆名"
                v-model="ruleForm.phone"
              >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item prop="bindPhone">
            <div class="input">
              <div class="label">手机号</div>
              <el-input placeholder="输入手机号码" v-model="ruleForm.bindPhone">
              </el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="yzm">
              <div class="input" style="width: 59.49vw">
                <div class="label">输入验证码</div>
                <el-input placeholder="输入验证码" v-model="ruleForm.smsCode">
                </el-input>
              </div>
              <div class="labelYzm" @click="getCode">
                <div v-if="isYzm">获取验证码</div>
                <HelloWorld v-else></HelloWorld>
              </div>
            </div>
          </el-form-item>

          <el-form-item prop="password">
            <div class="input">
              <div class="label">输入密码</div>
              <el-input
                placeholder="输入密码"
                v-model="ruleForm.password"
                type="password"
              >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item prop="truePassword">
            <div class="input">
              <div class="label">确认密码</div>
              <el-input
                placeholder="二次确认密码"
                v-model="ruleForm.truePassword"
                type="password"
              >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="input">
              <div class="label">推荐码</div>
              <el-input
                placeholder="输入推荐码"
                v-model="ruleForm.invitationCode"
                disabled
              >
              </el-input>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="bottom" @click="register">立即注册</div>
    </div>
    <div class="btns" v-else>
      <a href="https://apps.apple.com/cn/app/id6462688209"
        ><div class="ios">IOS版</div></a
      >
      <a
        href="https://guo-bo.oss-cn-zhangjiakou.aliyuncs.com/apk/%E5%9B%BD%E6%92%AD%E5%87%BA%E5%9B%BD_1.1.1.apk"
        ><div class="ios android">Android版下载</div></a
      >
    </div>
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
export default {
  components: {
    HelloWorld,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      console.log(1);
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.truePassword !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var valBindPhone = (rule, value, callback) => {
      const reg = /^1[3-9]\d{9}$/;
      if (value === "") {
        callback(new Error("请输入电话号码"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正确电话格式"));
      } else {
        callback();
      }
    };
    var valPhone = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (value === "") {
        callback(new Error("请输入邮箱"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正确邮箱"));
      } else {
        callback();
      }
    };
    return {
      userName: "",
      isRegister: true,
      // ruleForm: {
      //   pass: "",
      //   checkPass: "",
      //   age: "",
      // },
      ruleForm: {
        bindPhone: "",
        invitationCode: "",
        password: "",
        phone: "",
        smsCode: "",
        truePassword: "",
      },
      list: [
        "外语短视频讲中文一键拍摄",
        "跨境直播讲中文老外听外语",
        "外语弹幕看不懂实时翻译",
        "跨境知识付费项目招商",
      ],
      rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        truePassword: [{ validator: validatePass2, trigger: "blur" }],
        bindPhone: [{ validator: valBindPhone, trigger: "blur" }],
        phone: [{ validator: valPhone, trigger: "blur" }],
      },
      isYzm: true,
    };
  },
  mounted() {
    this.ruleForm.invitationCode = this.$route.query.invitationCode;
  },
  methods: {
    toPre() {
      this.isRegister = true;
    },
    register() {
      this.$axios({
        url: "https://preferred-api.guobo.shop/preferred/adminUser/invitingNewPosterRegister", //请求的后台接口
        method: "post", //get请求方式
        data: this.ruleForm, //传给后台的参数
      })
        .then((response) => {
          //请求成功
          console.log(response);
          if (response.data.code == 200) {
            this.isRegister = false;
            this.$message.success("注册成功");
          } else {
            this.$message.error(response.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
          //请求失败
          this.$message.error(error.message);
        });
    },
    getCode() {
      if (this.isYzm == true) {
        console.log(typeof this.ruleForm.bindPhone);
        const params = {
          phone: String(this.ruleForm.bindPhone),
          type: 7,
        };
        this.$axios({
          url: "https://preferred-api.guobo.shop/preferred/login/sendSms", //请求的后台接口
          method: "post", //get请求方式
          data: params, //传给后台的参数
        })
          .then((response) => {
            //请求成功
            console.log(response);
            if (response.data.code == 200) {
              this.$message.success("获取验证码成功");
            } else {
              this.$message.error(response.data.msg);
            }
          })
          .catch((error) => {
            console.log(error);
            //请求失败
            this.$message.error(error.message);
          });
        this.isYzm = false;
        setTimeout(() => {
          this.isYzm = true;
        }, 59000);
      }
    },
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.yzm {
  display: flex;
  justify-content: space-between;
}
a {
  text-decoration: none; /* 去除下划线 */
  color: inherit; /* 继承父元素的颜色 */
}
.labelYzm {
  width: 27.18vw;
  height: 15.38vw;
  text-align: center;
  line-height: 15.38vw;
  font-size: 3.08vw;
  background: linear-gradient(
    180deg,
    rgba(38, 103, 254, 1) 5.73%,
    rgba(188, 208, 255, 1) 100%
  );
  box-shadow: 0vw 1.03vw 1.03vw rgba(0, 0, 0, 0.25),
    inset 0vw 0.26vw 4.1vw rgba(255, 255, 255, 0.1),
    0vw 0.26vw 1.03vw rgba(0, 0, 0, 0.25);
  border-radius: 2vw;
  cursor: pointer;
}
.btns {
  margin: 11vw 0 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ios {
  margin: 0 4vw;
  width: 32.82vw;
  height: 10.26vw;
  background: linear-gradient(
    180deg,
    rgba(38, 103, 254, 1) 5.73%,
    rgba(188, 208, 255, 1) 100%
  );
  box-shadow: 0vw 1.03vw 1.03vw rgba(0, 0, 0, 0.25),
    inset 0vw 0.26vw 4.1vw rgba(255, 255, 255, 0.1),
    0vw 0.26vw 1.03vw rgba(0, 0, 0, 0.25);
  font-size: 4.1vw;
  font-weight: 500;
  line-height: 10.26vw;
  text-align: center;
}
.title {
  margin: 10.77vw 0 7vw;
  text-align: center;
  font-size: 5.13vw;
  font-weight: 600;
  letter-spacing: -0.08vw;
  line-height: 7.43vw;
  position: relative;
  color: rgba(0, 0, 0);
}
.return {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 20px;
}
.bottom {
  background: linear-gradient(
    180deg,
    rgba(38, 103, 254, 1) 5.73%,
    rgba(188, 208, 255, 1) 100%
  );
  box-shadow: 0vw 1.03vw 1.03vw rgba(0, 0, 0, 0.25),
    inset 0vw 0.26vw 4.1vw rgba(255, 255, 255, 0.1),
    0vw 0.26vw 1.03vw rgba(0, 0, 0, 0.25);
  text-align: center;
  font-size: 4.1vw;
  font-weight: 500;
  width: 87.95vw;
  height: 10.26vw;
  line-height: 10.26vw;
  margin: 0 auto 5vw;
  color: #fff;
}
.form {
  width: 89.23vw;
  margin: auto;
  margin-top: 4vw;
}
.input {
  position: relative;
}
.el-input,
.el-input__inner {
  height: 15.38vw !important;
  border-radius: 2vw !important;
}
.input .label {
  position: absolute;
  left: 20px;
  top: 0;
  left: 4vw;
  z-index: 10;
  transform: translate(0, -50%);
  background: rgba(38, 103, 254, 1);
  padding: 0vw 1.03vw 0vw 1.03vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 0 0 5px 5px;
  line-height: 1;
}
.container {
  color: #fff;
  overflow: hidden;
  background: linear-gradient(
    182.38deg,
    rgba(38, 103, 254, 0.35) 0%,
    rgba(167, 238, 248, 0.19) 42.19%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 100vw;
  /* height: 100vh; */
}
.top {
  left: 3.85vw;
  top: 24.87vw;
  width: 92.31vw;
  height: 33.85vw;
  opacity: 1;
  border-radius: 2.56vw;
  background: url("./assets/top.png") rgba(38, 103, 254);
  margin: auto;
  padding: 5vw;
  color: #fff;
  font-size: 6.15vw;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
</style>
